.header{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column; 
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 60px;
    padding-bottom: 70px;
    background-color: #001226;
}

.home{
    background-image: url('../../image/homeback.png');
    background-size: cover;
    background-position: center;
}

.nav{
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #001226;
    top: 0;
    left: 0;
    height: auto;
    padding: 20px 10px;
    position: fixed;
    z-index: 100;
    transition: all 500ms ease;
}



.img{
    width: 45px;
    height: 47px;
    
}

.img img{
    width: 100%;
    height: 100%;
    margin-left: 50px;
    
    
}

.fa-bars{
    font-size: 30px;
    color: #1EF7C4;
    display: none;
}

.fa-xmark{
    font-size: 30px;
    color: #1EF7C4;
    display: none;
}

.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 75%;
    margin-left: 230px;
    
}




.navbar ul{
    display: flex;
    gap: 20px;
    
    
    
    
}



.navbar li{
    border-bottom: 1px solid rgb(0, 0, 0);
    border-radius: 40px;
    
}



.navbar ul li a{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
}

.navbar ul li a:hover{
    color: #1EF7C4;
}

.heading{
    margin-top: 150px;
}

.heading h1{
    font-family: 'Random Grotesque Standard Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 70px;
    letter-spacing: 0.004em;
    color: #FFFFFF;
}

.heading p{
    font-family: 'Random Grotesque Standard Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: #FFFFFF;
    margin-top: 20px;
}

 .button{
    font-family: 'Random Grotesque Standard Bold';
    width: 300px;
    height: 67px;
    margin-top: 25px;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}

.frieght,
.industry,
.atm,
.knowledge,
.company{
    display: none;
    position: absolute;
    text-align: center;
}

.link-frieght:hover .frieght,
.link-industry:hover .industry,
.link-atm:hover .atm,
.link-knowledge:hover .knowledge,
.link-company:hover .company{
    display: block;
    background-color: #fff;
    height: auto;
    top: 60px;
    padding: 10px 6px;
}

.link-frieght:hover .frieght,
.link-industry:hover .industry,
.link-atm:hover .atm,
.link-knowledge:hover .knowledge{
    width: 200px;
}






.link-company:hover .company{
    width: 150px;
    
}

.link-frieght:hover .frieght ul,
.link-industry:hover .industry ul,
.link-atm:hover .atm ul,
.link-knowledge:hover .knowledge ul,
.link-company:hover .company ul
{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.link-frieght:hover .frieght ul a,
.link-industry:hover .industry ul a,
.link-atm:hover .atm ul a,
.link-knowledge:hover .knowledge ul a,
.link-company:hover .company ul a{
    font-size: 16px;
    color: #001226;
    font-weight: 700;
}

.link-frieght:hover .frieght ul a:hover,
.link-industry:hover .industry ul a:hover,
.link-atm:hover .atm ul a:hover,
.link-knowledge:hover .knowledge ul a:hover,
.link-company:hover .company ul a:hover{
    color: #1EF7C4;
}

@media (max-width: 1334px) {
    .header {
        height: auto;
        padding: 10px;
        padding-bottom: 54px;
        }
        .navbar.open {
        width: 100%;
        height: auto;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 60px;
        
        
        justify-content: space-between;
        background-color: inherit;
        gap: 100px;
        margin-left: -20px;
        }

        .img img{
            width: 100%;
            height: 100%;
            margin-left: 0;
            
            
        }
        .navbar.closed{
            width: 300px;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 70px;
            left: 200px;
            background-color: inherit;
            gap: 30px;
            display: none;
        }
    
        .nav{
            padding: 10px 20px;
            position: fixed;
        }
    
    
        .navbar ul li a{
            font-size: 16px;
        }
        .navbar button{
            display: none;
        }
    
        .fa-bars {
        display: block;
        }
    
        .fa-xmark {
            display: block;
        }
    
        .navbar ul {
        flex-direction: column;
        }
    
        .link-frieght:hover .frieght,
        .link-industry:hover .industry,
        .link-atm:hover .atm,
        .link-knowledge:hover .knowledge,
        .link-company:hover .company{
           
            width: 180px;
            top: 60px;
        } 
    
        .link-frieght:hover .frieght{
            
            top: 68px;
        }
    
        .link-company:hover .company{
            top: 200px;
        }
    
        .link-industry:hover .industry{
            top: 103px;
            
        }
    
        .link-atm:hover .atm{
            top: 142px;
            
        }
    
        .link-knowledge:hover .knowledge{
            top: 160px;
        }
    
        .heading {
        margin-top: 100px;
        }
    
        .heading h1,
        .heading p{
            text-align: center;
        }
    
        .heading h1{
            font-weight: 500;
            font-size: 56px;
            line-height: 50px;
            letter-spacing: 0.002em;
        }
    
        .heading p{
            font-weight: 200;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.004em;
        }
    
        .button{
            width: 260px;
            height: 67px;
            margin-top: 25px;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            display: flex;
            justify-self: center;
            align-self: center;
        }


}

@media (max-width: 820px) {
    .header {
    height: auto;
    padding: 10px;
    }
    .img img{
        width: 100%;
        height: 100%;
        margin-left: 0;
        
        
        
    }
    .navbar.open {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    position: absolute;
    
    
    justify-content: space-between;
    background-color: inherit;
    gap: 100px;
    margin-left: -25px;
    }
    .navbar.closed{
        width: 300px;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 70px;
        left: 200px;
        background-color: inherit;
        gap: 30px;
        display: none;
    }

    .nav{
        padding: 10px 20px;
        position: fixed;
    }


    .navbar ul li a{
        font-size: 16px;
    }
    .navbar button{
        display: none;
    }

    .fa-bars {
    display: block;
    }

    .fa-xmark {
        display: block;
    }

    .navbar ul {
    flex-direction: column;
    }

    .link-frieght:hover .frieght,
    .link-industry:hover .industry,
    .link-atm:hover .atm,
    .link-knowledge:hover .knowledge,
    .link-company:hover .company{
        
        width: 200px;
        top: 60px;
    } 

    .link-frieght:hover .frieght{
        
        top: 80px;
    }

    .link-company:hover .company{
        top: 200px;
    }

    .link-industry:hover .industry{
        top: 80px;
        
    }

    .link-atm:hover .atm{
        top: 120px;
        
    }

    .link-knowledge:hover .knowledge{
        top: 160px;
    }

    .heading {
    margin-top: 100px;
    }

    .heading h1,
    .heading p{
        text-align: center;
    }

    .heading h1{
        font-weight: 500;
        font-size: 56px;
        line-height: 50px;
        letter-spacing: 0.002em;
    }

    .heading p{
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.004em;
    }

    .button{
        width: 260px;
        height: 67px;
        margin-top: 25px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        justify-self: center;
        align-self: center;
    }
}
    
@media (max-width: 420px) {
    .header {
    height: auto;
    padding: 5px;
    padding-bottom: 54px;
    }
    .img img{
        width: 100%;
        height: 100%;
        margin-left: 0;
        
        
        
    }

    .nav{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #001226;
        top: 0;
        height: auto;
        padding: 10px 25px;
        transition: all 500ms ease;
        
    }
    

    .navbar {
        width: 250px;
        padding: 10px;
        

    }

    .navbar.open {
        width: 100%;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 66px;
        
        background-color: inherit;
        gap: 30px;
        
        }
        .navbar.closed{
            width: 300px;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 70px;
            left: 200px;
            background-color: inherit;
            gap: 30px;
            display: none;
        }

        .link-frieght:hover .frieght,
        .link-industry:hover .industry,
        .link-atm:hover .atm,
        .link-knowledge:hover .knowledge,
        .link-company:hover .company{
        left: 100px;
        width: 192px;
        top: 60px;
    } 

    .link-company:hover .company{
        top: 200px;
        left: 100px;
        width: 192px;
    }

    .link-industry:hover .industry{
        top: 80px;
        left: 100px;
        width: 192px;
    }

    .link-atm:hover .atm{
        top: 120px;
        left: 100px;
        width: 192px;
    }

    .link-knowledge:hover .knowledge{
        top: 160px;
        left: 100px;
        width: 192px;
    }

    
    
    .navbar ul li a {
        font-size: 16px;
        text-align: center;
    }
    
    .heading h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.002em;
        margin-bottom: 20px;
    }
    
    .heading p {
        
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.002em;
        margin-left: 28px;
        margin-right: 28px;
    }

    .container-cotent h1{
        font-size: 32px !important;
    }

    .container-cotent p{
        font-size: 16px !important;
    }

    .heading p br {
        display: none;
    }
    
    .button {
        width: 200px;
        height: 50px;
        font-weight: 500;
        font-size: 16px;
        line-height: 14px;
    }


}

@media (max-width: 375px){
    .header {
        height: auto;
        padding: 5px;
    }
    .img img{
        width: 100%;
        height: 100%;
        margin-left: 0;
        
        
    }

    .nav{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #001226;
        top: 0;
        height: auto;
        padding: 10px 25px;
        transition: all 500ms ease;
        
    }
    

    .navbar {
        width: 100%;
        padding: 10px;
        

    }

    .navbar.open {
        width: 100%;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 66px;
        
        background-color: inherit;
        gap: 30px;
        
        }
        .navbar.closed{
            width: 300px;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 70px;
            left: 200px;
            background-color: inherit;
            gap: 30px;
            display: none;
        }

        .link-frieght:hover .frieght,
        .link-industry:hover .industry,
        .link-atm:hover .atm,
        .link-knowledge:hover .knowledge,
        .link-company:hover .company{
        left: 100px;
        width: 192px;
        top: 60px;
    } 

    .link-company:hover .company{
        top: 200px;
        left: 100px;
        width: 192px;
    }

    .link-industry:hover .industry{
        top: 80px;
        left: 100px;
        width: 192px;
    }

    .link-atm:hover .atm{
        top: 120px;
        left: 100px;
        width: 192px;
    }

    .link-knowledge:hover .knowledge{
        top: 160px;
        left: 100px;
        width: 192px;
    }

    
    
    .navbar ul li a {
        font-size: 16px;
        text-align: center;
    }
    
    .heading h1 {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.002em;
        margin-bottom: 20px;
    }
    
    .heading p {
        
        font-weight: 200;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.002em;
        margin-left: 28px;
        margin-right: 28px;
    }

    .container-cotent h1{
        font-size: 32px !important;
    }

    .container-cotent p{
        font-size: 16px !important;
    }

    .heading p br {
        display: none;
    }
    
    .button {
        width: 200px;
        height: 50px;
        font-weight: 500;
        font-size: 16px;
        line-height: 14px;
    }
}

@media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 374px){
        .header{
            position: relative;
            width: 380px;
            height: auto;
            display: flex;
            flex-direction: column; 
            padding-bottom: 54px;
        
            background-color: #001226;
        }
        .img img{
            width: 100%;
            height: 100%;
            margin-left: 0;
            
            
        }
        .nav{
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background-color: #001226;
            top: 0;
            height: auto;
            padding: 10px 25px;
            transition: all 500ms ease;
            
        }
        
    
        .navbar {
            width: 100%;
            padding: 10px;
            
    
        }
    
        .navbar.open {
            width: 100%;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 66px;
            
            background-color: inherit;
            gap: 30px;
            
            }
            .navbar.closed{
                width: 300px;
                flex-direction: column;
                align-items: center;
                position: absolute;
                top: 70px;
                left: 200px;
                background-color: inherit;
                gap: 30px;
                display: none;
            }
    
            .link-frieght:hover .frieght,
            .link-industry:hover .industry,
            .link-atm:hover .atm,
            .link-knowledge:hover .knowledge,
            .link-company:hover .company{
            left: 100px;
            width: 192px;
            top: 60px;
        } 
    
        .link-company:hover .company{
            top: 200px;
            left: 100px;
            width: 192px;
        }
    
        .link-industry:hover .industry{
            top: 80px;
            left: 100px;
            width: 192px;
        }
    
        .link-atm:hover .atm{
            top: 120px;
            left: 100px;
            width: 192px;
        }
    
        .link-knowledge:hover .knowledge{
            top: 160px;
            left: 100px;
            width: 192px;
        }
    
        
        
        .navbar ul li a {
            font-size: 16px;
            text-align: center;
        }
        
        .heading h1 {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            letter-spacing: 0.002em;
            margin-bottom: 20px;
        }
        
        .heading p {
            
            font-weight: 200;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.002em;
            margin-left: 28px;
            margin-right: 28px;
        }
    
        .container-cotent h1{
            font-size: 32px !important;
        }
    
        .container-cotent p{
            font-size: 16px !important;
        }
    
        .heading p br {
            display: none;
        }
        
        .button {
            width: 200px;
            height: 50px;
            font-weight: 500;
            font-size: 16px;
            line-height: 14px;
        }


    }
    
    


  