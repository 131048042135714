.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 50px;
    border: 1px solid #1EF7C4;
    border-radius: 12px;
    margin-top: 40px;

  }
  :root{
    --toastify-color-success: #1EF7C4;
  }
  
  .contact-form h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  
  label {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  input,
  textarea {
    font-size: 1.2rem;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid #1EF7C4;
    margin-top: 5px;
    margin-bottom: 10px;
    width: 100%;
    
  }
  
  button {
    background-color: #1EF7C4;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    border-radius: 12px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    
  }
  
  button:hover {
    background-color: #1EF7C4;
  }
  
  @media (min-width: 768px) {
    .contact-form {
      max-width: 100%;
    }
  
    .form-group {
      flex-direction: row;
    }
  
    .form-group label {
      width: 30%;
      margin-right: -115px;
      margin-bottom: 0;
    }
  
    input,
    textarea {
      width: 70%;
    }
  
    button {
      width: 200px;
    }
  }
  