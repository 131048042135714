.main{
    position: relative;
    background-color: #fff;
    margin: 50px;
    padding: 10px;
    /* width: 100%; */
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

.main-content{
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.main-content .container,
.main-content .container-reverse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 20px;
}

.main-content .container .img,
.main-content .container-reverse .img{
    width: auto;
    height: auto;
}

.main-content .container .img img,
.main-content .container-reverse .img img{
    width: 100%;
    height: 100%;
}

.main-content .container-reverse{
    flex-direction: row-reverse;
}

.container-cotent{
    width: 250px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.container-cotent h1{
    font-family: 'Random Grotesque Standard Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 70px;
    letter-spacing: 0.004em;
    color: #000000;
}

.container-cotent p{
    font-family: 'Random Grotesque Standard Book';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.015em;
    color: #000000;
    margin-top: -10px;
}

.container-cotent a{
    font-family: 'Random Grotesque Standard Book';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.004em;
    color: #1EF7C4;
    margin-top: 10px;
}

.container-cotent a:hover{
    color: #001226;
}

.main-frieght{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 150px;
}

.main-frieght .Text{
    text-align: center;
    width: 80%;
}

.main-frieght .Text h1{
    font-family: 'Random Grotesque Standard Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 68px;
    letter-spacing: 0.004em;
    color: #000000;
}

.main-frieght .Text p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.015em;
    color: #000000;
}

.main-frieght .box{
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-between;
    margin-top: 150px;
}


.main-frieght .box .box-content{
    position: relative;
    width: 30%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 20px 15px;
}

.main-frieght .box .box-content:hover{
    top: -20px;
}


.main-frieght .box .box-content .img{
    width: 100px;
    height: 100px;
}

.main-frieght .box .box-content .img img{
    width: 100%;
    height: 100%;
    margin-bottom: -140px;
}


.main-frieght .box .box-content span{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.015em;
    color: #1EF7C4;
    margin-bottom: -70px;
}

.main-frieght .box .box-content p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
}

.box-content:nth-child(2) {
    position: relative;
    top: -120px;
}

.box-content:nth-child(2):hover {
    position: relative;
    top: -200px;
}

.main-location{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 150px;
    margin-bottom: 50px;
}

.main-location .location-title h1{
    font-family: 'Random Grotesque Standard Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 68px;
    letter-spacing: 0.004em;
    color: #000000;
}

.main-location .location-title p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.015em;
    color: #000000;
    margin-top: -10px;
}

.main-location .box{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.main-location .box .box-text{
    position: relative;
    width: 25%;
}

.main-location .box .box-text span{
    font-family: 'Random Grotesque Standard Book';
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.015em;
    color: #000000;
}

.main-location .box .box-text p{
    font-family: 'Random Grotesque Standard Book';
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.015em;
    color: #000000;
}

.box-text:nth-child(2) {
    position: relative;
    top: 15px;
}


@media (max-width: 820px){
    
    .main-frieght .box{
        flex-direction: column;
        gap: 50px;
    }

    .main-frieght .box .box-content{
        width: 50%;
    }

    .box-content:nth-child(2) {
        position: relative;
        top: 0px;
    }
}

@media (max-width: 420px){

    .main-content .container,
    .main-content .container-reverse {
    display: flex;
    flex-direction: column-reverse;
}
    
    .main-frieght .box .box-content{
        width: 100%;
    }

    .container-cotent p {
        line-height: 22px;
    }

    .main-frieght .Text{
        width: 100%;
    }

    .main-frieght .Text h1{
        font-family: 'Random Grotesque Standard Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.004em;
        margin-bottom: 10px;
        
    }

    .main-frieght .Text p{
        font-family: 'Random Grotesque Standard Book';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.015em;

    }

    .main-frieght .box .box-content p{
        font-size: 16px !important;
    }

    .main-frieght .Text p br{
        display: none;

    }

    .main-location{
        align-items: center;
    }

    .main-location .location-title h1{
        font-family: 'Random Grotesque Standard Bold';
        font-size: 32px;
        text-align: center;
    }

    .main-location .location-title p{
        font-size: 16px;
        line-height: 22px;
        text-align: center;
    }

   

    .main-location .box{
        flex-direction: column;
        gap: 20px;
    }


    .main-location .box .box-text{
        width: 100%;
        text-align: center;
    }

    .main-location .box .box-text span{
        font-family: 'Random Grotesque Standard Book';
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.015em;
        color: #000000;
    }

    
    .main-location .box .box-text p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.015em;
        color: #000000;
        margin-top: 10px;
    }

    .box-text:nth-child(2) {
        top: 0px;
    }

}








