.trackpages{
    margin: 160px 196px 160px 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    gap: 30px;
    background: #EFEFEF;
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.modal {
   
    margin-top: 165px;
  }

p{
    line-height: 22px;
    margin-top: 10px;
}
.trackpages p{
    font-style: normal; 
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}

.trackpages h2{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 16px;
}

.trackpages input{
    width: 100%;
    height: auto;
    padding: 20px;
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    outline: none;
    border: 1px solid #EFEFEF;
    font-size: 16px;
}

.title{
    font-family: 'Random Grotesque Standard Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 70px;
    letter-spacing: 0.004em;
    color: #1EF7C4;
    margin: 163px 0 210px 0;
}

.body{
    padding: 20px 100px;
    display: flex;
    flex-direction: column;
    
}

.body h1{
    font-family: 'Random Grotesque Standard Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 70px;
    letter-spacing: 0.004em;
}

.body-content{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
}

.body-content ul li{
    list-style-type: disc;
    list-style-position : inside;
    margin: 0 25px;
}

.content-bold span{
    font-weight: 700;
    margin-right: 10px;
}

.green-span span{
    color: #1EF7C4;
    font-weight: 700;
}



.mission-box {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-gap: 2rem;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: space-between;
}

.mission-box  .box-pot{
    position: relative;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 20px 15px;
    margin-top: 30px;
}

.mission-box  .box-pot:hover{
    top: -10px;
}

.mission-box  .box-pot .img{
    width: 100px;
    height: 100px;
}

.mission-box  .box-pot .img img{
    width: 100%;
    height: 100%;
}

.mission-box  .box-pot span{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.015em;
    color: #1EF7C4;
}

.mission-box  .box-pot p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #000000;
}

.form{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 80%;
}

.form input,
.form textarea{
    background: #EFEFEF;
    border-radius: 20px;
    border: none;
    outline: none;
    padding: 10px 0px 10px 10px;
    color: #000000;
    font-size: 16px;
}

.form .name{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form .name input{
    width: 48%;
}


@media (max-width: 820px){
    .trackpages{
        margin: 141px 65px 65px 65px; 
        padding: 30px;
    }

    .body-content{
        font-size: 16px;
    }
    .title{
        width: 300px;
    }

    .mission-box{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
    }
}

@media (max-width: 768px){
    .trackpages p{
        font-size: 16px;
    }
}

@media (max-width: 420px){
    .trackpages{
        margin: 47px 10px;
        padding: 20px;
    }

    .trackpages p{
        font-size: 16px;
    }

    .trackpages input{
        padding: 10px;
    }

    .title{
        width: 200px;
        font-size: 32px;
        margin-left: 10px;
        line-height: 30px;
    }



    .body{
        padding: 50px 10px;
    }

    .body h1{
        font-size: 32px;
        line-height: 30px;
    }

    .body-content{
        font-size: 16px;
        line-height: 21px;
    }

    .mission-box{
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, auto);
        width: 80%;
        align-self: center;
    }

    .form input,
    .form textarea{
        padding: 5px 0px 5px 5px;
        font-size: 16px;
    }
}