@import url('https://fonts.cdnfonts.com/css/random-grotesque-standard?styles=138799,138797');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Random Grotesque Standard Book', sans-serif;
}

body{
  width: 100%;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 200px;
  height: 40px;
  background: #1EF7C4;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  color: #000;
  margin-top: 12px;
}


.head-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 155px;
  height: 40px;
  background: #1EF7C4;
  border-radius: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  color: #000;
  margin-top: 0 !important;
}

a {
  color: #000;
}

